class HttpError extends Error {
  constructor(status, message) {
    super(message);
    this.name = "HttpError";
    this.status = status;
  }
}

class BadRequestError extends HttpError {
  constructor(message) {
    super(400, typeof message === 'object' ? JSON.stringify(message) : message);
    this.name = "BadRequestError";
  }
}

class UnauthorizedError extends HttpError {
  constructor(message) {
    super(401, message);
    this.name = "UnauthorizedError";
  }
}

class ForbiddenError extends HttpError {
  constructor(message) {
    super(403, message);
    this.name = "ForbiddenError";
  }
}

class NotFoundError extends HttpError {
  constructor(message) {
    super(404, message);
    this.name = "NotFoundError";
  }
}

class RequestEntityTooLarge extends HttpError {
  constructor(message) {
    super(413, message);
    this.name = "RequestEntityTooLarge";
  }
}

class ValidationError extends HttpError {
  constructor(message, path) {
    super(422, message);
    this.name = "ValidationError";
    this.path = path;
  }
}

class ServerError extends HttpError {
  constructor(status, message) {
    super(status, message);
    this.name = "ServerError";
  }
}

export default {
  HttpError,
  BadRequestError,
  UnauthorizedError,
  ForbiddenError,
  NotFoundError,
  RequestEntityTooLarge,
  ValidationError,
  ServerError
};
