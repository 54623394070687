import queryString from "query-string";
import { toasts } from "store";

export let isEmpty = function (value) {
  return (
    !(value instanceof Date) &&
    (value === null ||
      value === undefined ||
      value === "" ||
      (typeof value === "object" && (value.length === 0 || Object.keys(value).length === 0)))
  );
};

export let cmpObjects = function (a1, a2) {
  if ((a1 instanceof Array && a2 instanceof Array) || (a1 instanceof Object && a2 instanceof Object)) {
    let a1Props = Object.getOwnPropertyNames(a1);
    let a2Props = Object.getOwnPropertyNames(a2);
    if (a1Props.length !== a2Props.length) {
      return false;
    }
    for (let i in a1) {
      if (typeof a1[i] === "function") {
        continue;
      } else if (!cmpObjects(a1[i], a2[i])) {
        return false;
      }
    }
    return true;
  } else {
    return a1 === a2;
  }
};

export let queryParams = function (params, except) {
  let result = [];
  for (let key in params) {
    if (except !== undefined && except.includes(key)) {
      continue;
    }
    if (params[key] !== "" && params[key] !== null) {
      result.push(key + "=" + params[key]);
    }
  }
  return result.join("&");
};

export let mergeSearchParams = function (paramsA, paramsB) {
  let result = {};
  let key;
  for (key in paramsA) {
    result[key] = paramsA[key];
  }
  for (key in paramsB) {
    result[key] = paramsB[key];
  }
  return result;
};

export let parseSearchParams = function (value, boolean_fields, integer_fields) {
  let result = {};
  let queryParams = queryString.parse(value);

  for (let param in queryParams) {
    if (queryParams[param]) {
      if (boolean_fields.indexOf(param) !== -1) {
        result[param] = queryParams[param] === "true";
      } else if (integer_fields.indexOf(param) !== -1) {
        let int_value = parseInt(queryParams[param]);
        if (!isNaN(int_value)) {
          result[param] = int_value;
        }
      } else {
        result[param] = queryParams[param];
      }
    }
  }
  return result;
};

let addLeadZero = function (num) {
  return (num < 10 ? "0" : "") + num;
};

let ampmFormat = function (hours) {
  hours = hours % 12;
  hours = hours ? hours : 12;
  return addLeadZero(hours);
};

let addOffset = function (offset) {
  if (offset === 0) {
    return "+00:00";
  } else {
    offset *= -1;
    return (offset >= 0 ? "+" : "-") + addLeadZero(Math.floor(offset / 60)) + ":" + addLeadZero(offset % 60);
  }
};

export let strftime = function (date, format) {
  let result = format;

  let map = {
    "%d": (date) => addLeadZero(date.getDate()),
    "%m": (date) => addLeadZero(date.getMonth() + 1),
    "%Y": (date) => date.getFullYear(),
    "%H": (date) => addLeadZero(date.getHours()),
    "%I": (date) => ampmFormat(date.getHours()),
    "%M": (date) => addLeadZero(date.getMinutes()),
    "%S": (date) => addLeadZero(date.getSeconds()),
    "%p": (date) => (addLeadZero(date.getHours()) >= 12 ? " pm" : " am"),
    "%z": (date) => addOffset(date.getTimezoneOffset())
  };

  for (let key in map) {
    while (result.indexOf(key) > -1) {
      result = result.replace(key, map[key](date));
    }
  }

  return result;
};

export let duration = function (seconds) {
  let s = 0,
    m = 0,
    h = 0;

  if (seconds > 59) {
    s = seconds % 60;
    seconds = seconds - s;
    seconds = seconds / 60;
  } else {
    return `${Math.round(seconds)} second${seconds === 1 ? "" : "s"}`;
  }

  if (seconds > 59) {
    m = seconds % 60;
    seconds = seconds - m;
    seconds = seconds / 60;
  } else {
    return `${seconds} minute${seconds === 1 ? "" : "s"}, ${Math.round(s)} second${s === 1 ? "" : "s"}`;
  }

  if (seconds > 23) {
    h = seconds % 24;
    seconds = seconds - h;
    seconds = seconds / 24;
    return `${seconds} day${seconds === 1 ? "" : "s"}, ${h} hour${h === 1 ? "" : "s"}, ${m} minute${m === 1 ? "" : "s"
      }, ${Math.round(s)} second${s === 1 ? "" : "s"}`;
  } else {
    return `${seconds} hour${seconds === 1 ? "" : "s"}, ${m} minute${m === 1 ? "" : "s"}, ${Math.round(s)} second${s === 1 ? "" : "s"
      }`;
  }
};

export let UUID4 = function () {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

export let deepCopy = function (obj) {
  let objType = Object.prototype.toString.call(obj);

  if (objType === "[object Object]") {
    let result = {};
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        result[key] = deepCopy(obj[key]);
      }
    }
    return result;
  } else if (objType === "[object Array]") {
    let result = [];
    for (let i = 0; i < obj.length; i++) {
      result.push(deepCopy(obj[i]));
    }
    return result;
  } else {
    return obj;
  }
};

export let cast = function (value) {
  if (typeof value === "boolean") {
    return value ? "Yes" : "No";
  }
  return value;
};

export let fileSize = function (value) {
  const units = ["Bytes", "KB", "MB", "GB"];
  let i = 0;
  while (value >= 1024) {
    value /= 1024;
    i++;
  }
  return `${Math.round(value * 100) / 100} ${units[i]}`;
};

export let getMinMaxDate = function (rows, side, minOrMax) {
  const endDates = rows.map((row) => row[side]).sort();
  return minOrMax === "min" ? endDates[0] : endDates.slice(-1)[0];
};

export let stableSort = function (array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

let cmpDates = function (date1, date2, operator) {
  let date1Obj = {
    year: date1.getFullYear(),
    month: date1.getMonth(),
    day: date1.getDate(),
  };
  let date2Obj = {
    year: date2.getFullYear(),
    month: date2.getMonth(),
    day: date2.getDate(),
  };
  switch (operator) {
    case "eq":
      return JSON.stringify(date1Obj) === JSON.stringify(date2Obj);
    case "ne":
      return JSON.stringify(date1Obj) !== JSON.stringify(date2Obj);
    case "gt":
      return JSON.stringify(date1Obj) > JSON.stringify(date2Obj);
    case "gte":
      return JSON.stringify(date1Obj) >= JSON.stringify(date2Obj);
    case "lt":
      return JSON.stringify(date1Obj) < JSON.stringify(date2Obj);
    case "lte":
      return JSON.stringify(date1Obj) <= JSON.stringify(date2Obj);
  }
}

export let parseFilter = function (item_field, operator, filter, is_date = false) {
  if (is_date) return cmpDates(new Date(item_field), new Date(filter), operator);
  else {
    switch (operator) {
      case "in":
        return filter.includes(item_field);
      case "nin":
        return !filter.includes(item_field);
      case "eq":
        return filter === item_field;
      case "ne":
        return filter !== item_field;
      case "gt":
        return item_field > filter;
      case "gte":
        return item_field >= filter;
      case "lt":
        return item_field < filter;
      case "lte":
        return item_field <= filter;
      case "like":
        return item_field.includes(filter);
      default:
        return false;
    }
  }

};

export let parseOperator = function (operator) {
  switch (operator) {
    case "in":
      return "IN";
    case "nin":
      return "NOT IN";
    case "eq":
      return "=";
    case "ne":
      return "≠";
    case "gt":
      return ">";
    case "gte":
      return "≥";
    case "lt":
      return "<";
    case "lte":
      return "≤";
    default:
      return "";
  }
};

export let getTitle = function (array, id) {
  return array.filter((item) => item?.id === id)[0]?.title;
};

export const showToast = (title) => {
  toasts.add("Данная функция отключена на тестовом стенде", title, "info");
};


// Search item
const DEFAULT_SEARCH_PARAMS = {
  page: 1,
  limit: 10
};

export const getSearchParams = (search = "") => {
  return mergeSearchParams(
    DEFAULT_SEARCH_PARAMS,
    parseSearchParams(search, [], ["page", "limit"])
  );
};

export const search = async function (storage, profile, error, query = [], all = false, search = "") {
  function enrich_items(items) {
    if (!isEmpty(items)) {
      for (let i = 0; i < items.length; i++) {
        items[i]["created"] = strftime(new Date(items[i]["created"]), profile.datetimeFormat);
        items[i]["modified"] = strftime(new Date(items[i]["modified"]), profile.datetimeFormat);
      }
    }
    return items;
  }
  let params = all
    ? { all: true }
    : { ...getSearchParams(search), q: JSON.stringify(query) }
  try {
    let data = await storage.search(params);
    return {
      items: enrich_items(data.items),
      meta: data.meta
    }
  } catch (e) {
    if (e instanceof error.HttpError) {
      toasts.add(e.message, "Получение данных", "error");
    }
  }
};