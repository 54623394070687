import Base from "usecase/base.js";
import { queryParams } from "../utils";

class ConnectedResources extends Base {
  constructor() {
    super("/conn-resrs");
  }

  async updateItemFields(itemId, data) {
    return await this.fetch(this.url + "/" + itemId, {
      method: "PATCH",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    });
  }

  async searchPaths(itemId, searchParams) {
    let data = await this.fetch(this.url + "/pages/" + itemId + "?" + queryParams(searchParams));
    data.meta["pages"] = Math.ceil(data.meta.found / data.meta.limit) || 1;
    return data;
  }

  async addPaths(data, itemId) {
    return await this.fetch(this.url + '/' + itemId, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({paths: data}),
    });
  }

  async setWaitTimeOffForPath(itemId, pathId) {
    return await this.fetch(this.url + '/' + itemId + '/set-wait-time/' + pathId, {method: "DELETE"});
  }

  async setWaitTimeForPath(itemId, pathId, data) {
    return await this.fetch(this.url + '/' + itemId + '/set-wait-time/' + pathId, {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    });
  }

  async setMainPage(itemId, pathId, method) {
    return await this.fetch(this.url + '/' + itemId  + "/set-main-page/"+ pathId , {method: method});
  }

  async updatePathField(itemId, pathId, fieldName, data) {
    return await this.fetch(this.url + "/pages/" + itemId  + "/"+ fieldName + "/" + pathId , {
      method: "PATCH",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    });
  }

  async deletePath(itemId, pathId) {
    return await this.fetch(this.url + "/" + itemId + "/page/" + pathId, {method: "DELETE"});
  }

}

export default ConnectedResources;
