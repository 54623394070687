import Base from "usecase/base.js";

class Segments extends Base {
  constructor() {
    super("/segments");
  }

  async list() {
    let data = await this.search({page: 1, limit: 200});
    let result = [];
    for (let i = 0; i < data.items.length; i++) {
      result.push({id: data.items[i].id, title: data.items[i].title});
    }
    return result;
  }

  async create(title, entity, isStatic, query) {
    return await this.fetch("/segments", {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({title: title, entity: entity, static: isStatic, query: query})
    });
  }
}

export default Segments;
