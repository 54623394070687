import {decorate, observable, action, computed} from "mobx";

import UsecaseProfile from "usecase/profile.js";

class Profile {
  name = "";
  master = false;
  dateFormat = "%Y-%m-%d";
  timeFormat = "%H:%M:%S";

  storage = new UsecaseProfile();

  get datetimeFormat() {
    return this.dateFormat + " " + this.timeFormat;
  }

  get checkPermission() {
    return this.master;
  }

  async update() {
    let data = await this.storage.get();
    this.name = data.item.name;
    this.master = data.item.master;
    this.dateFormat = data.item.date_format;
    this.timeFormat = data.item.time_format;
  }
}

Profile = decorate(Profile, {
  name: observable,
  master: observable,
  dateFormat: observable,
  timeFormat: observable,
  datetimeFormat: computed,
  update: action,
  checkPermission: computed
});

export default new Profile();
