import Base from "usecase/base.js";

class ContactPolicy extends Base {
  constructor() {
    super("/contact-policy");
  }

  // async updateTitle(itemId, data) {
  //   return await super.update(itemId, {
  //     title: data.title
  //   });
  // }
}

export default ContactPolicy;