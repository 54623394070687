import {decorate, observable, action} from "mobx";

import UsecaseSegments from "usecase/segments.js";

class Segments {
  list = [];

  storage = new UsecaseSegments();

  async update() {
    let data = await this.storage.search({all: true});

    // TODO: убрать, заменить на кнопку, по нажатию на которую выбираются все записи из списка
    this.list.push({id: "all_items", title: "All segments"});

    for (let i = 0; i < data.items.length; i++) {
      this.list.push({id: data.items[i].id, title: data.items[i].title});
    }
  }
}

Segments = decorate(Segments, {
  list: observable,
  update: action
});

export default new Segments();
