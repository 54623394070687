import Base from "usecase/base.js";

class Settings extends Base {
  constructor() {
    super("/settings");
  }

  async get() {
    return await this.fetch(this.url);
  }

  async update(data) {
    return await this.fetch(this.url, {
      method: "PATCH",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    });
  }
}

export default Settings;
