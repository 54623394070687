import { decorate, observable, action } from "mobx";

import UsecaseConnectedResources from "usecase/connected_resources.js";

import { search } from "utils.js";
import { profile } from "store";
import error from "usecase/errors.js";

class ConnectedResources {
  list = [];
  meta = {
    found: 0,
    pages: 1
  };
  items = [];

  storage = new UsecaseConnectedResources();

  async update() {
    // console.log(await this.storage.addPaths({"paths": ["path1", "path2"]}, 7));

    let data = await search(this.storage, profile, error, [], true);
    this.meta = data.meta;
    this.items = data.items;
    for (let i = 0; i < data.items.length; i++) {
      this.list.push(data.items[i]);
    }
  }

  async search(query = []) {
    let data = await search(this.storage, profile, error, query);
    this.meta = data.meta;
    this.items.splice(0);
    data.items.map(item => this.items.push(item));
  }
}

ConnectedResources = decorate(ConnectedResources, {
  list: observable,
  meta: observable,
  items: observable,
  update: action,
  search: action
});

export default new ConnectedResources();
