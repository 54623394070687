import { decorate, observable, action } from "mobx";

import UsecaseSegmentFields from "usecase/segment_fields.js";
import UsecaseCampaigns from "usecase/campaigns.js";
import UsecaseContactPolicies from "usecase/contact_policy.js";
import segments from "./segments";
import brands from "./brands";

class SegmentFields {
  listFields = [];
  listIds = {};

  storage = new UsecaseSegmentFields();
  campaigns = new UsecaseCampaigns();
  contact_policies = new UsecaseContactPolicies();

  async update() {
    let data = await this.storage.search({ all: true });
    let campaigns_data = await this.campaigns.search({ all: true });
    let contact_policies_data = await this.contact_policies.search({ all: true });
    for (let i = 0; i < data.items.length; i++) {
      if (data.items[i].type !== 'ids')
        this.listFields.push({ type: data.items[i].type, title: data.items[i].title, children: data.items[i].children });
      else
        // TODO: убрать, заменить на кнопку, по нажатию на которую выбираются все записи из списка
        this.listIds = {
          ...data.items[i].children,
          "segment_id": segments.list,
          "brand_id": brands.list,
          "campaign_id": [{ id: "all_items", title: "All campaigns" }].concat(campaigns_data.items),
          "contact_id": [{ id: "all_items", title: "All contact policies" }].concat(contact_policies_data.items)
        };
    }
  }
}

SegmentFields = decorate(SegmentFields, {
  listFields: observable,
  listIds: observable,
  update: action
});

export default new SegmentFields();
