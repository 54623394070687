import {decorate, observable, action} from "mobx";

import UsecaseSettings from "usecase/settings.js";

class Settings {
  companyTitle = "";
  additionalCustomerFields = [];

  storage = new UsecaseSettings();

  async update() {
    let data = await this.storage.get();
    this.companyTitle = data.item.title;
    this.additionalCustomerFields = data.item.additional_customer_fields;
  }
}

Settings = decorate(Settings, {
  companyTitle: observable,
  additionalCustomerFields: observable,
  update: action
});

export default new Settings();
