import Base from "usecase/base.js";

class Campaigns extends Base {
  constructor() {
    super("/campaigns");
  }

  async results() {
    const data = {
      meta: {found: 1, limit: 10, page: 1},
      items: [{id: 1, start: "2020-12-11T11:11:22.108000+00:00", finish: "2020-12-12T11:11:22.108000+00:00", status: 4}]
    };
    return data;
  }

  async result() {
    const data = {
      item: {
        start: "2020-12-11T11:11:22.108000+00:00",
        finish: "2020-12-12T11:11:22.108000+00:00",
        quantity: 331,
        entity: "visitors",
        charts: [
          {
            id: "1",
            title: "Opened emails – «News»",
            type: "line",
            group: "hour",
            data: {
              labels: [
                "2020-12-11T11:11:22.108000+00:00",
                "2020-12-11T12:11:22.108000+00:00",
                "2020-12-11T13:11:22.108000+00:00",
                "2020-12-11T14:11:22.108000+00:00",
                "2020-12-11T15:11:22.108000+00:00",
                "2020-12-11T16:11:22.108000+00:00",
                "2020-12-11T17:11:22.108000+00:00",
                "2020-12-11T18:11:22.108000+00:00",
                "2020-12-11T19:11:22.108000+00:00",
                "2020-12-11T20:11:22.108000+00:00",
                "2020-12-11T21:11:22.108000+00:00",
                "2020-12-11T22:11:22.108000+00:00",
                "2020-12-11T23:11:22.108000+00:00",
                "2020-12-12T00:11:22.108000+00:00",
                "2020-12-12T01:11:22.108000+00:00",
                "2020-12-12T02:11:22.108000+00:00",
                "2020-12-12T03:11:22.108000+00:00",
                "2020-12-12T04:11:22.108000+00:00",
                "2020-12-12T05:11:22.108000+00:00",
                "2020-12-12T06:11:22.108000+00:00",
                "2020-12-12T07:11:22.108000+00:00",
                "2020-12-12T08:11:22.108000+00:00",
                "2020-12-12T09:11:22.108000+00:00",
                "2020-12-12T10:11:22.108000+00:00",
                "2020-12-12T11:11:22.108000+00:00"
              ],
              datasets: [
                {
                  label: "Opened emails",
                  data: [0, 1, 5, 10, 8, 15, 11, 10, 10, 7, 3, 4, 2, 10, 16, 30, 20, 10, 9, 12, 19, 20, 17, 24]
                }
              ]
            }
          },
          {
            id: "2",
            title: "Emails – «News»",
            type: "pie",
            group: "hour",
            data: {labels: ["Sent", "Opened"], datasets: [{label: null, data: [331, 273]}]}
          },
          {
            id: "3",
            title: "Yandex.Direct – «Banner 1»",
            type: "line",
            group: "hour",
            data: {
              labels: [
                "2020-12-11T11:11:22.108000+00:00",
                "2020-12-11T12:11:22.108000+00:00",
                "2020-12-11T13:11:22.108000+00:00",
                "2020-12-11T14:11:22.108000+00:00",
                "2020-12-11T15:11:22.108000+00:00",
                "2020-12-11T16:11:22.108000+00:00",
                "2020-12-11T17:11:22.108000+00:00",
                "2020-12-11T18:11:22.108000+00:00",
                "2020-12-11T19:11:22.108000+00:00",
                "2020-12-11T20:11:22.108000+00:00",
                "2020-12-11T21:11:22.108000+00:00",
                "2020-12-11T22:11:22.108000+00:00",
                "2020-12-11T23:11:22.108000+00:00",
                "2020-12-12T00:11:22.108000+00:00",
                "2020-12-12T01:11:22.108000+00:00",
                "2020-12-12T02:11:22.108000+00:00",
                "2020-12-12T03:11:22.108000+00:00",
                "2020-12-12T04:11:22.108000+00:00",
                "2020-12-12T05:11:22.108000+00:00",
                "2020-12-12T06:11:22.108000+00:00",
                "2020-12-12T07:11:22.108000+00:00",
                "2020-12-12T08:11:22.108000+00:00",
                "2020-12-12T09:11:22.108000+00:00",
                "2020-12-12T10:11:22.108000+00:00",
                "2020-12-12T11:11:22.108000+00:00"
              ],
              datasets: [
                {
                  label: "Views",
                  data: [10, 4, 15, 40, 86, 32, 111, 54, 10, 72, 33, 41, 26, 70, 46, 30, 20, 10, 91, 32, 69, 40, 17, 24]
                }
              ]
            }
          }
        ]
      }
    };
    return data;
  }
}

export default Campaigns;
