import Base from "usecase/base.js";

class Brands extends Base {
  constructor() {
    super("/brands");
  }

  toMap(brands) {
    let result = {};
    for (let i = 0; i < brands.length; i++) {
      result[brands[i].id] = brands[i].title;
    }
    return result;
  }
}

export default Brands;
