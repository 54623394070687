import {decorate, observable, action} from "mobx";

import UsecaseConnectedModules from "usecase/connected_modules.js";

class ConnectedModules {
  list = [];

  storage = new UsecaseConnectedModules();

  async update() {
    let data = await this.storage.search({all: true});

    for (let i = 0; i < data.items.length; i++) {
      this.list.push({id: data.items[i].id, title: data.items[i].title});
    }
  }
}

ConnectedModules = decorate(ConnectedModules, {
  list: observable,
  update: action
});

export default new ConnectedModules();
