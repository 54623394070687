import { decorate, action } from "mobx";

import brands from "store/brands.js";
import profile from "store/profile.js";
import settings from "store/settings.js";
import segments from "store/segments.js";
import segment_fields from "store/segment_fields.js"
import connected_modules from "store/connected_modules.js";
import connected_resources from "store/connected_resources.js";

class System {
  async load() {
    return await Promise.all([
      profile.update(),
      settings.update(),
      brands.update(),
      segments.update(),
      segment_fields.update(),
      connected_modules.update(),
      connected_resources.update(),
    ]);
  }
}

System = decorate(System, {
  load: action
});

export default new System();
