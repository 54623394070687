import {decorate, observable, action} from "mobx";

import UsecaseBrands from "usecase/brands.js";

class Brands {
  list = [];
  map = {};

  storage = new UsecaseBrands();

  async update() {
    let data = await this.storage.search({all: true});
    for (let i = 0; i < data.items.length; i++) {
      this.list.push({id: data.items[i].id, title: data.items[i].title});
      this.map[data.items[i].id] = {
        title: data.items[i].title,
        additional_customer_fields: data.items[i].additional_customer_fields,
        subscriptions: data.items[i].subscriptions.reduce((subscriptions, subscription) => {
          subscriptions[subscription.field_name] = subscription.title;
          return subscriptions;
        }, {})
      };
    }
  }

  getTitle(id) {
    return this.map[id]?.title;
  }
}

Brands = decorate(Brands, {
  list: observable,
  map: observable,
  update: action
});

export default new Brands();
